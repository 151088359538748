jQuery(document).ready(function ($) {
  $("#blog-filter a").click(function (e) {
    e.preventDefault();

    // Remove "active" class from all <li> elements
    $("#blog-filter li").removeClass("active");

    // Add "active" class to the clicked <li>
    $(this).closest("li").addClass("active");
    var category = $(this).data("category");

    $.ajax({
      type: "POST",
      url: ajax_object.ajaxurl, // Use localized ajaxurl
      data: {
        action: "filter_posts",
        category: category,
        nonce: ajax_object.nonce, // Add the nonce
      },
      success: function (response) {
        $("#send-posts-blog").html(response);
        console.log("success");
      },
      error: function (xhr, status, error) {
        console.error("AJAX Error:", error); // Log AJAX error
      },
    });

    // Function to reapply excerpt length and "read more" filters
    function applyExcerptFilters() {
      console.log("loaded the apply filters button");
      $.ajax({
        type: "POST",
        url: ajax_object.ajaxurl, // Use localized ajaxurl
        data: {
          action: "apply_excerpt_length_filter",
          nonce: ajax_object.nonce, // Add the nonce
        },
        success: function (response) {
          console.log("Excerpt filters reapplied successfully");
        },
        error: function (xhr, status, error) {
          console.error("Error reapplying excerpt filters:", error);
        },
      });
    }

    // Call applyExcerptFilters() when the page loads initially
    applyExcerptFilters();
  });
});
