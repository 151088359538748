function setFiltersFromUrl() {
  var urlParams = new URLSearchParams(window.location.search);
  urlParams.forEach(function (value, key) {
    var element = $("#filter-form").find("[name='" + key + "']");
    if (element.length === 0) {
      element = $("#filter-form").find("[name='" + key + "[]']");
    }
    if (element.is(":checkbox")) {
      element.filter("[value='" + value + "']").prop("checked", true);
      element.siblings("a.checkbox-toggle").toggleClass("selected", true);
    } else {
      element.val(value);
    }
  });
}

jQuery(document).ready(function ($) {
  var ajaxurl = ajax_object.ajaxurl;
  var page = 1;
  var loading = false;

  setFiltersFromUrl();
  filterPosts();

  $("#view-all").click(function (e) {
    e.preventDefault();
    $("input[type='checkbox']").prop("checked", false);
    $("#filter-form").submit();
  });

  $("#filter-form").submit(function (e) {
    e.preventDefault();
    page = 1; // Reset page to 1 on new filter submission
    filterPosts();
  });

  $("#filter-form").on("change", "input[type='checkbox']", function () {
    $(this).siblings("a.checkbox-toggle").toggleClass("selected", this.checked);
    $("#filter-form").submit();
  });

  function filterPosts() {
    var formDataArray = $("#filter-form").serializeArray();
    var filteredData = {};
    $.each(formDataArray, function (index, obj) {
      if (
        obj.name !== "nonce" &&
        obj.name !== "_wp_http_referer" &&
        obj.value !== "undefined" &&
        obj.value !== ""
      ) {
        var cleanName = obj.name.replace(/\[\]$/, ""); // Remove [] from the name
        if (filteredData[cleanName]) {
          if (!Array.isArray(filteredData[cleanName])) {
            filteredData[cleanName] = [filteredData[cleanName]];
          }
          filteredData[cleanName].push(obj.value);
        } else {
          filteredData[cleanName] = obj.value;
        }
      }
    });

    $.ajax({
      type: "POST",
      url: ajaxurl,
      data: $.param(filteredData) + "&action=filter_reports&page=" + page,
      success: function (response) {
        if (page === 1) {
          $("#send-posts").html(response);
        } else {
          console.log("this is when we load more posts");
        }
        loading = false;

        // Constructing the URL without unwanted parameters
        var urlParams = new URLSearchParams();
        $.each(filteredData, function (key, value) {
          if (Array.isArray(value)) {
            value.forEach(function (v) {
              urlParams.append(key + "[]", v); // Append [] for arrays
            });
          } else {
            urlParams.append(key, value);
          }
        });
        history.pushState(
          null,
          null,
          window.location.pathname + "?" + urlParams.toString()
        );
      },
      error: function (xhr, status, error) {
        console.error("AJAX Error:", error);
        loading = false;
      },
    });
  }

  $(window).scroll(function () {
    var container = $("#send-posts");
    var scrollTop = $(window).scrollTop();
    var containerTop = container.offset().top;
    var containerHeight = container.outerHeight();
    var windowHeight = $(window).height();

    if (
      scrollTop + windowHeight >= containerTop + containerHeight - 200 &&
      !loading
    ) {
      loading = true;
      page++;
      filterPosts();
    }
  });

  $("#filter-toggle-btn").click(function () {
    console.log("clicked the filter toggle");
    $("#report-filter").slideToggle();
  });

  // Handle back and forward button navigation
  window.onpopstate = function () {
    setFiltersFromUrl();
    filterPosts();
  };
});
